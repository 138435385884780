import React, { useState, useCallback, useEffect, useContext } from "react"
import { navigate } from 'gatsby'
import { Link } from "gatsby"
import UserContext from './context/UserContext'
import { Modal, Loading, Input, Button } from '@geist-ui/react'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'

import { magic } from "../lib/magic"

const Login = (props) => {
  const [phone, setPhone] = useState("")
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [open, setOpen] = useState()

  const { loggedin, setLoggedIn } = useContext(UserContext)

  useEffect(() => {
      if (loggedin) {
        setOpen(false);
        if(props.page !== 'nopage') {
          navigate('/' + props.page + '/');
        }
      } else {
        if(props.page !== 'nopage') {
          setOpen(true);
        }
      }  
  }, [loggedin, props.page])

  const login = useCallback(async () => {
    setIsLoggingIn(true);
    try {
      await magic.auth.loginWithSMS({
        phoneNumber: phone
      });
      setOpen(false);
      setLoggedIn(true);
      setIsLoggingIn(false);
      navigate('/' + props.page + '/');
    } catch(err) {
      setIsLoggingIn(false);
    }
  }, [phone])


  return ( <>{props.modal ?
    <Modal visible={open} disableBackdropClick>
      <div className="login">
        {props.page === 'account' && <h4>Sign Up or Login</h4>}
        {props.page === 'order' && <h4>Add Your Phone Number</h4>}
        {!isLoggingIn && <><PhoneInput width="100%" placeholder="Phone" id="phone" defaultCountry="US" value={phone} 
            onChange={setPhone} contentEditable />
        <Button type="secondary" width="100%" onClick={login} disabled={isLoggingIn} shadow>Submit</Button></>}
        {isLoggingIn && <Loading />}
      </div>
      <Modal.Action onClick={() => (setOpen(false), props.onChange())}>Close</Modal.Action>
    </Modal> : <>
    {open && <div className="login-section"> 
      <div className="login">
          {props.page === 'account' && <h4>Sign Up or Login</h4>}
          {props.page === 'order' && <h4>Add Your Phone Number</h4>}
          {!isLoggingIn && <><PhoneInput width="100%" placeholder="Phone" id="phone" defaultCountry="US" value={phone} 
              onChange={setPhone} contentEditable />
          <Button type="secondary" width="100%" onClick={login} disabled={isLoggingIn} shadow>Submit</Button></>}
          {isLoggingIn && <Loading />}
        </div>
       </div>}
      </>}
    </>)
}

export default Login