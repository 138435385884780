import React from 'react'

export const defaultUserContext = {
  loggedin: true,
  currentuser: [],
  admin: false,
  subscriptions: [],
  orders: [],
  upcomingorders: [],
  setLoggedIn: () => {},
  setCurrentUser: () => {},
  setAdmin: () => {},
  setSubscriptions: () => {},
  setOrders: () => {},
  setUpcomingOrders: () => {}
}

const UserContext = React.createContext(defaultUserContext)

export default UserContext
