import React, { useState, useEffect, useContext, useCallback } from "react"
import { Link, navigate } from 'gatsby'
import Login from './Login'
import UserContext from './context/UserContext'
import { Modal, Loading, Input, Button, Spacer, Note } from '@geist-ui/react'
import { ShoppingBag, User, MapPin, Disc, ArrowRight, CreditCard, BarChart  } from '@geist-ui/react-icons'

import { magic } from "../lib/magic"

import BBB from '../../static/assets/SBC.png'

export default function HeadingBar (props) {
 
 const [loggingin, setLoggingIn] = useState()
 const [needname, setNeedName] = useState()
 const [phone, setPhone] = useState()
 const [name, setName] = useState()
 const [email, setEmail] = useState()
 const [loading, setLoading] = useState()
 const [error, setError] = useState()

 const { loggedin, setLoggedIn, currentuser, setCurrentUser, admin, setAdmin, upcomingorders, setUpcomingOrders } = useContext(UserContext)

 useEffect(() => {
   magic.user.isLoggedIn().then(setLoggedIn);
 }, [magic])


 const getUser = useCallback(async () => {
    try {
      const { phoneNumber, publicAddress } = await magic.user.getMetadata();
      fetch(`/.netlify/functions/fauna-find/`, {
        body: JSON.stringify({
          index: 'users_by_id',
          id: publicAddress
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
            if(response.data.length > 0) {
              setCurrentUser(response.data);
              fetch(`/.netlify/functions/fauna-find/`, {
                body: JSON.stringify({
                  index: 'orders_by_user_and_fulfilled',
                  id: [publicAddress, false]
                 }),
                 method: 'POST'}).then((response) => {
                  return response.json()}).then((response) => {
                    setUpcomingOrders(response.data || []);
                  })
                .catch(error => console.log(error));
            }
            else {
              setNeedName(publicAddress);
              setPhone(phoneNumber);
            }
        })
      .catch(error => console.log(error));
      if(publicAddress === '0x11acb7bdb9d4f552d0552d534acb1cf40ffc8744') {
        setAdmin(true);
      }
    } catch {
      console.log('error');
    }
  }, []);

 useEffect(() => {
   if(loggedin) {
     getUser();
   }
   else {
     setCurrentUser([])
   }
 }, [loggedin])

 function createuser() {
   setLoading(true);
   setError();
   fetch(`/.netlify/functions/stripe-create-user/`, {
            body: JSON.stringify({
              phone: phone,
              name: name.toUpperCase(),
              id: needname,
              email: email.toUpperCase()
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => {
              if(response.data.id) {
                   fetch(`/.netlify/functions/fauna-create/users`, {
                      body: JSON.stringify({
                        id: needname,
                        name: name.toUpperCase(),
                        phone: phone,
                        customer: response.data.id,
                        email: email.toUpperCase()
                      }),
                    method: 'POST'}).then((response) => {
                      return response.json()}).then((response) => {
                        getUser();
                        setLoading();
                        setNeedName(false);
                      })
                   .catch(error => (setLoading(), setError('There has been an error, please try again.')));
               }
              else {
                setLoading();
                setError(response.data.body || 'There has been an error, please try again.');
              }
             })
              .catch(error => (console.log(error), setLoading(false)));
 }

  return (<>
    {loggingin && <Login page={loggingin} onChange={() => setLoggingIn('nopage')} modal="true" />}
    <Modal visible={needname} disableBackdropClick>
      <div className="login">
        {error && <Note label="">{error}</Note>}
        <h4>What's Your Name?</h4>
        {!loading && <><Input width="100%" htmlType="text" scale={6/3} placeholder="First & Last Name" value={name} 
            onChange={(e) => setName(e.target.value)} />
            <Spacer h={.5} />
            <Input width="100%" htmlType="email" scale={6/3} placeholder="Email Address" value={email} 
            onChange={(e) => setEmail(e.target.value)} />
        <Button type="secondary" width="100%" style={{marginTop:'30px'}} onClick={createuser} disabled={!name || !email} loading={loading} shadow>Submit</Button></>}
        {loading && <Loading />}
      </div>
    </Modal>
    <div className="heading-bar">
    	<div className="left">
    		<span>
          <User size={24} onClick={() => loggedin ? navigate('/account/') : setLoggingIn('account')} />
          {admin && <BarChart size={24} className="padding-left" onClick={() => navigate('/admin/')} />}
         </span>
    		<span className="link" onClick={() => navigate('/#locations')}>Find Us</span>
    		<span className="link" onClick={() => navigate('/#bagels')}>Bagels</span>
    	</div>
    	<img
          src={BBB}
          className="logo"
          alt="Brown Box Bagels"
          width="200px"
          onClick={() => navigate('/')}
        />
        <div>
    		<span className="link" onClick={() => navigate('/#about')}>About</span>
    		<span className="link" onClick={() => navigate('/order/')}>Order</span>
    		<ShoppingBag onClick={() => navigate('/order/')} />
    	</div>
    </div>
    <div className="bottom-bar">
    	<div onClick={() => navigate('/#locations')}>
    		<MapPin size={20} />
    		<br />
    		<span>Find A Location</span>
    	</div>
    	<div onClick={() => navigate('/order/')}>
    		<Disc size={20} />
    		<br />
    		<span>Order Now</span>
    	</div>
    </div>
    {upcomingorders.length > 0 && <div className="upcoming-order" onClick={() => navigate('/account/')}>
      <Disc size={16}/><h5>Upcoming Order<span></span>update it here</h5><ArrowRight size={10} />
    </div>}
  </>)
}