import React from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Header from "../components/Header"
import Footer from "../components/Footer"
import PropTypes from 'prop-types'
import { GeistProvider, CssBaseline } from '@geist-ui/react'

import StoreContext, { defaultStoreContext } from '../components/context/StoreContext'
import UserContext, { defaultUserContext } from '../components/context/UserContext'

import { magic } from "../lib/magic"


class Layout extends React.Component {


 constructor(props){
  super(props);

  this.state = {
    user: {
      ...defaultUserContext,
      setLoggedIn: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            loggedin: value
          }
        }))
      },
      setCurrentUser: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            currentuser: value
          }
        }))
      },
      setSubscriptions: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            subscriptions: value
          }
        }))
      },
      setOrders: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            orders: value
          }
        }))
      },
      setUpcomingOrders: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            upcomingorders: value
          }
        }))
      },
      setAdmin: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            admin: value
          }
        }))
      },
    },
  }
}


  render() {

    const { children } = this.props

    return  <>
    <Helmet>
      <html lang="en" />
    </Helmet>
    <GeistProvider themeType={this.state.mode}>
    <CssBaseline />
    <UserContext.Provider value={this.state.user}>
    <header className={`site-header ${this.state.mode}`} id="header">
        <Header />
     </header>
    <div className={`site-wrapper ${this.state.mode} ${this.state.user.upcomingorders.length > 0  && `draft`}`} id="site-wrapper">
      {children}
    </div>
     <Footer />
    </UserContext.Provider>
    </GeistProvider>
    </>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

